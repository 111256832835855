import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import "./Help.scss"
import { useStaticQuery, graphql } from "gatsby"
// import { AnimationOnScroll } from "react-animation-on-scroll"
import GenerateLink from "../common/site/GetLink"
import parse from "html-react-parser"
import { StaticImage } from "gatsby-plugin-image"
const Customer = props => {

  const data = useStaticQuery(graphql`
    query {
      glstrapi {
        globalConfig {
          Lets_Work_Together_Module {
            Module_Heading
            Module_Content
            Module_CTA {
              CTA_Link {
                Primary_URL
              }
              CTA_Label
            }
          }

          Book_a_Valuation_Module {
            Module_Heading
            Module_Content
            Module_CTA {
              CTA_Link {
                Primary_URL
              }
              CTA_Label
            }
          }
        }
      }
    }
  `)

  var item = data.glstrapi.globalConfig.Lets_Work_Together_Module

  props.module === "Book_a_Valuation_Module" &&
    (item = data.glstrapi.globalConfig.Book_a_Valuation_Module[0])
  props.module === "Lets_Work_Together_Module" &&
    (item = data.glstrapi.globalConfig.Lets_Work_Together_Module)

  return (
    <React.Fragment>
      <section className={`help ${props.className} `}>
        <Container>
          <Row>
            <Col md={12} lg={6} className="get-zoom order-2 help-img-wrap">
              <div className="help-img">
                <StaticImage src="../../images/Lets_Work_Together_2.png" alt="Let’s work together - McCone Properties" />
              </div>
            </Col>
            <Col md={12} lg={6} className="">
              <div className="help-info">
                <span className="sm-title">{item.Module_Heading}</span>
                {parse(item.Module_Content)}
                {/* <h2>Let’s work together.</h2>

                <p>
                  Nullam suscipit nisi sed nibh consectetur, nec imperdiet
                  turpis interdum. Aliquam eget tellus a leo pretium
                  vestibulum.
                </p> */}
                {item.Module_CTA && (
                  <div className="btn-wrap">
                    {item.Module_CTA.map((item, index) => (
                      <GenerateLink
                        link={item.CTA_Link.Primary_URL}
                        class={index === 0 ? "btn" : "btn btn-secondary"}
                      >
                        <span>{item.CTA_Label}</span>
                        <i className="icon-right"></i>
                      </GenerateLink>
                    ))}
                    {/* <Link href="#" className="btn">
                  List your property
                    <i className="icon-right"></i>
                  </Link>
                  <Link href="#" className="btn btn-secondary">
                    Get in touch
                    <i className="icon-right"></i>
                  </Link> */}
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  )
}
export default Customer
